import { createConsumer } from "@rails/actioncable"

$(document).ready(function() {
  $('.open-chat').click(function(){
    $('#chat-view').show()
    scrollBottom()
  })

  $('.close-chat').click(function(){
    $('#chat-view').hide()
  })
  const chat_id = $('#chat-view').data('id')
  const chat_view = $('#chat-view').data('view')

  $('.icon-send').click(function() {
    if ( $('#text-input').val().length > 0) {
      const formData = new FormData()
      formData.append('chat_message[image]', null)
      formData.append('chat_message[body]', $('#text-input').val())
      formData.append('chat_message[chat_id]', chat_id)
      $.ajax({
        url: '/chat_messages',
        method: 'POST',
        beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
        data: formData,
        dataType: 'script',
        processData: false,
        contentType: false
      })
      clearData()
    }
  })

  $('#images').change(function(){
    const formData = new FormData()
    formData.append('chat_message[image]', $('#images')[0].files[0])
    formData.append('chat_message[body]', '')
    formData.append('chat_message[chat_id]', chat_id)
    $.ajax({
      url: '/chat_messages',
      method: 'POST',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: formData,
      dataType: 'script',
      processData: false,
      contentType: false
    })
    clearData()
  })

  function clearData() {
    $('#text-input').val('')
    $('#images').val('')
  }

  function scrollBottom() {
    let list = document.getElementById('list-message')
    list.scrollTop = list.scrollHeight
  }

  if (chat_id) {
    createConsumer().subscriptions.create({'channel': 'ChatChannel', 'chat_id': chat_id}, {
      connected() {
        console.log('connected')
      },

      disconnected() {
        // Called when the subscription has been terminated by the server
      },

      received(data) {
        let list = $('#list-message')
        if (chat_view === 'user') {
          if (data.chat_message.writer_type === 'Guest') {
            if (data.chat_message.image.url) {
              list.append( "<div class='my-message has-image'><img src='"+ data.chat_message.image.url + "'></img></div>" )
            } else {
              list.append( "<div class='my-message'><div>" + data.chat_message.body + "</div></div>" )
            }
            scrollBottom()
          } else if (data.chat_message.writer_type === 'User') {
            if (data.chat_message.image.url) {
              list.append( "<div class='partner-message has-image'><img src='"+ data.chat_message.image.url + "'></img></div>" )
            } else {
              list.append( "<div class='partner-message'><div>" + data.chat_message.body + "</div></div>" )
            }
            scrollBottom()
          }
        } else {
          $(`#notification_${chat_id}`).remove()
          seen(chat_id)
          if (data.chat_message.writer_type === 'User') {
            if (data.chat_message.image.url) {
              list.append( "<div class='my-message has-image'><img src='"+ data.chat_message.image.url + "'></img></div>" )
            } else {
              list.append( "<div class='my-message'><div>" + data.chat_message.body + "</div></div>" )
            }
            scrollBottom()
          } else if (data.chat_message.writer_type === 'Guest') {
            if (data.chat_message.image.url) {
              list.append( "<div class='partner-message has-image'><img src='"+ data.chat_message.image.url + "'></img></div>" )
            } else {
              list.append( "<div class='partner-message'><div>" + data.chat_message.body + "</div></div>" )
            }
            scrollBottom()
          }
        }
      }
    })
  }
})

function seen(chat_id) {
  $.ajax({
    url: `/admin/chats/${chat_id}/seen`,
    method: 'GET',
  })
}